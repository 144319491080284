"use strict";

var _interopRequireDefault = require("/Users/zhangjun/MyWork/\u91CF\u5B50\u667A\u8054/\u4E00\u57CE\u4E00\u9091/ycyy-qd/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMenusTree = getMenusTree;
exports.buildMenus = buildMenus;
exports.add = add;
exports.del = del;
exports.edit = edit;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

function getMenusTree() {
  return (0, _request.default)({
    url: 'api/menus/tree',
    method: 'get'
  });
}

function buildMenus() {
  return (0, _request.default)({
    url: 'api/menus/build',
    method: 'get'
  });
}

function add(data) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'post',
    data: data
  });
}

function del(ids) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'delete',
    data: ids
  });
}

function edit(data) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'put',
    data: data
  });
}

var _default = {
  add: add,
  edit: edit,
  del: del,
  getMenusTree: getMenusTree
};
exports.default = _default;