"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var container = {
  state: {
    terminal: 3,
    // 画布选择的设备
    activeComponent: {},
    // 选中模板数据
    componentsData: [] // 模板组件数据

  },
  mutations: {
    SET_TERMINAL: function SET_TERMINAL(state, terminal) {
      state.terminal = terminal;
    },
    SET_ACTIVECOMPONENT: function SET_ACTIVECOMPONENT(state, activeComponent) {
      state.activeComponent = activeComponent;
    },
    SET_COMPONENTSDATA: function SET_COMPONENTSDATA(state, componentsData) {
      state.componentsData = componentsData;
    }
  }
};
var _default = container;
exports.default = _default;