"use strict";

var _interopRequireDefault = require("/Users/zhangjun/MyWork/\u91CF\u5B50\u667A\u8054/\u4E00\u57CE\u4E00\u9091/ycyy-qd/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _logo = _interopRequireDefault(require("@/assets/images/logo.jpg"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '电商后台管理',
      logo: _logo.default
    };
  }
};
exports.default = _default;